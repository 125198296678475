import React from "react";

const BriefcaseCampaign = () => {
  return (
    <div className="sm:w-[80%] mx-auto">
      <div className="relative w-full">
        <iframe
          className="top-0 left-0 w-full h-[300vh] sm:h-[180vh] border-0"
          src="https://docs.google.com/forms/d/e/1FAIpQLScbJR3rFVr8Qza7sqp1ZoTBgSybIhdtsqc9pM1O_SKmtrs4aQ/viewform?embedded=true"
          allowFullScreen
          title="Google Form"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default BriefcaseCampaign;
